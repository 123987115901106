<template>
  <div class="content">
    <b-container>
      <b-row
        align-v="center"
        :align-h="this.$route.params.slug ? 'center' : ''"
      >
        <b-col md="5">
          <b-form
            @submit.prevent="doLogin"
            novalidate
            class="login-form"
             v-if="!this.$route.params.slug"
          >
            <div class="wrap_logo_default">
              <img src="/global_assets/images/logo_purple.png" />
            </div>
            <b-card class="mb-0">
              <div class="text-center mb-3">
                <h5 class="mb-0">Log In to your account</h5>
              </div>
              <b-form-group
                class="form-group-feedback form-group-feedback-left"
                :state="usernameValidate"
                invalid-feedback="Username not found, please try another."
              >
                <b-form-input
                  placeholder="Username"
                  :state="usernameValidate"
                  v-model="username"
                />
                <div class="form-control-feedback">
                  <i class="icon-user text-indigo"></i>
                </div>
              </b-form-group>
              <b-form-group
                class="form-group-feedback form-group-feedback-left"
                :state="passwordValidate"
                invalid-feedback="Password doesn't match."
              >
                <b-form-input
                  type="password"
                  placeholder="Password"
                  :state="passwordValidate"
                  v-model="password"
                />
                <div class="form-control-feedback">
                  <i class="icon-lock2 text-indigo"></i>
                </div>
              </b-form-group>
              <b-form-group>
                <div class="d-flex align-items-center">
                  <div class="form-check pl-0 mb-0">
                    <label for="" class="form-check-label">
                      <b-checkbox class="form-input-styled">Remember Me</b-checkbox>
                    </label>
                  </div>
                  <a href="#" class="ml-auto">Forgot password?</a>
                </div>
              </b-form-group>
              <div class="form-group">
                <b-button variant="indigo" block class="bg-indigo" type="submit">
                  Log In<i class="icon-circle-right2 ml-2"></i>
                </b-button>
              </div>
              <div class="form-group text-center text-muted content-divider">
                <span class="px-2">or Log In with</span>
              </div>
              <div class="form-group text-center">
                <b-button block variant="outline" class="bg-indigo text-indigo btn-icon border-2">
                  <i class="icon-google"></i> Log In With Google
                </b-button>
              </div>
              <div class="form-group text-center text-muted content-divider">
                <span class="px-2">Don't have an account?</span>
              </div>
              <div class="form-group">
                <b-button
                  variant="light"
                  block
                  :to="{ name: 'BoAuth', params: { slug: 'sign-up' } }"
                >Sign up</b-button>
              </div>
              <span class="form-text text-center text-muted">By continuing, you're confirming that you've read our <a
                  href="#">Terms &amp; Conditions</a> and <a href="#">Cookie Policy</a></span>
            </b-card>
          </b-form>
          <b-form
            @submit.prevent="doSignup"
            novalidate
            class="login-form"
            v-else
          >
            <div class="wrap_logo_default">
              <img src="/global_assets/images/logo_purple.png" />
            </div>
            <b-card class="mb-0">
              <div class="text-center mb-3">
                <h5 class="mb-0">Sign Up</h5>
              </div>
              <b-form-group
                class="form-group-feedback form-group-feedback-left"
              >
                <b-form-input
                  placeholder="Email Business"
                  type="email"
                />
                <div class="form-control-feedback">
                  <i class="icon-envelop text-indigo"></i>
                </div>
              </b-form-group>
              <b-form-group
                class="form-group-feedback form-group-feedback-left"
              >
                <b-form-input
                  type="password"
                  placeholder="Password"
                />
                <div class="form-control-feedback">
                  <i class="icon-lock2 text-indigo"></i>
                </div>
              </b-form-group>
              <b-form-group
                class="form-group-feedback form-group-feedback-left"
              >
                <b-form-input
                  type="password"
                  placeholder="Confirm Password"
                />
                <div class="form-control-feedback">
                  <i class="icon-lock2 text-indigo"></i>
                </div>
              </b-form-group>
              <div class="form-group">
                <b-button variant="indigo" block class="bg-indigo" type="submit">
                  Sign Up<i class="icon-circle-right2 ml-2"></i>
                </b-button>
              </div>
              <div class="form-group text-center text-muted content-divider">
                <span class="px-2">or</span>
              </div>
              <div class="form-group text-center">
                <b-button block variant="outline" class="bg-indigo text-indigo btn-icon border-2">
                  <i class="icon-google"></i> Sign Up With Google
                </b-button>
              </div>
              <div class="form-group text-center text-muted content-divider">
                <span class="px-2">Already a user?</span>
              </div>
              <div class="form-group">
                <b-button
                  variant="light"
                  block
                  :to="{ name: 'BoAuth' }"
                >Log In</b-button>
              </div>
              <span class="form-text text-center text-muted">By continuing, you're confirming that you've read our <a
                  href="#">Terms &amp; Conditions</a> and <a href="#">Cookie Policy</a></span>
            </b-card>
          </b-form>
        </b-col>
      </b-row>
      <div class="bg_abs_login" v-if="!this.$route.params.slug">
        <b-img src="/global_assets/images/login-graph.jpg" />
        <div class="wrap_info_text">
          <h1>Welcome Back!</h1>
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
            ea commodo </p>
        </div>
      </div>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      username: '',
      password: '',
      authUsername: 'lingkar9',
      authPassword: '@lingkar9!',
      doValidate: false,
    }
  },
  computed: {
    usernameValidate() {
      if (!this.doValidate) return null
      return this.username == this.authUsername
    },
    passwordValidate() {
      if (!this.doValidate) return null
      return this.password == this.authPassword
    }
  },
  methods: {
    doLogin() {
      this.$router.push({ name: 'ProjectDashboard', params: 'rencana-anggaran-alutista' })
    },
    doSignup() {
      this.doValidate = true
      this.$router.push({ name: 'ProjectSetup' })
      this.$store.commit('setAuth')
    },
  }
}
</script>